package de.rakhman.schlag

import androidx.compose.animation.animateColorAsState
import androidx.compose.animation.core.tween
import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentWidth
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.Button
import androidx.compose.material.ButtonDefaults
import androidx.compose.material.MaterialTheme
import androidx.compose.material.Shapes
import androidx.compose.material.Text
import androidx.compose.runtime.*
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.Brush
import androidx.compose.ui.graphics.Shadow
import androidx.compose.ui.graphics.drawscope.Stroke
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.ui.tooling.preview.Preview
import schlagdenvorstand.composeapp.generated.resources.Res
import schlagdenvorstand.composeapp.generated.resources.lorbeer
import schlagdenvorstand.composeapp.generated.resources.mitarbeiter
import schlagdenvorstand.composeapp.generated.resources.vorstand

const val GAMES_COUNT = 10

enum class Team(val label: String, val image: DrawableResource?) {
    None("", null),
    Left("Vorstand", Res.drawable.vorstand),
    Right("Mitarbeiter", Res.drawable.mitarbeiter),
}

fun List<Team>.score(wonBy: Team): Int = withIndex().filter { it.value == wonBy }.sumOf { it.index + 1 }

data class GameState(
    val gamesWon: List<Team>
) {
    init {
        require(gamesWon.size == GAMES_COUNT)
    }

    fun score(team: Team): Int {
        return gamesWon.score(team)
    }

    fun hasScored(game: Int, team: Team): Boolean {
        return gamesWon[game] == team
    }

    fun update(gameIndex: Int, wonBy: Team): GameState {
        val updatedGamesWon = gamesWon.toMutableList()
        updatedGamesWon[gameIndex] = wonBy
        return this.copy(gamesWon = updatedGamesWon)
    }
}

@Composable
@Preview
fun App() {
    MaterialTheme {
        var gameState by remember { mutableStateOf(GameState(List(GAMES_COUNT) { Team.None })) }

        fun updateGame(index: Int, team: Team) {
            gameState = gameState.update(index, team)
        }

        Box(
            Modifier
                .fillMaxWidth()
                .fillMaxHeight()
                .background(
                    Brush.radialGradient(
                        colors = listOf(Color.White, Color(173F / 255, 173F / 255, 173F / 255)),
                        radius = 1500f
                    )
                ),
            contentAlignment = Alignment.Center,
        ) {
            Image(painterResource(Res.drawable.lorbeer), null)

            val shape = RoundedCornerShape(36.dp)
            Column(
                modifier = Modifier
                    .wrapContentWidth()
                    .clip(shape)
                    .background(
                        Brush.radialGradient(
                            colors = listOf(Color(0xffffde03), Color(0xffffbd0e)),
                        )
                    )
                    .border(
                        3.dp,
                        Brush.sweepGradient(listOf(Color.White, Color.Gray, Color.White, Color.Gray, Color.White)),
                        shape = shape
                    )
                    .padding(vertical = 40.dp, horizontal = 20.dp),
                horizontalAlignment = Alignment.CenterHorizontally,
                verticalArrangement = Arrangement.Center,
            ) {
                Row(horizontalArrangement = Arrangement.Center) {
                    for (i in 0 until GAMES_COUNT) {
                        ScoreButton(gameState, i, Team.None, padding = 11.dp) { updateGame(i, Team.None) }
                    }
                }

                Row(horizontalArrangement = Arrangement.Center) {
                    TeamComponent(gameState, Team.Left) { updateGame(it, Team.Left) }
                    TeamComponent(gameState, Team.Right) { updateGame(it, Team.Right) }
                }
            }
        }
    }
}

@Composable
fun ScoreButton(gameState: GameState, index: Int, team: Team, padding: Dp = 8.dp, onClick: () -> Unit) {
    val isScored = gameState.hasScored(index, team)

    val backgroundColor by animateColorAsState(if (isScored) Color.White else Color.Black, animationSpec = tween(durationMillis = 500))
    val contentColor by animateColorAsState(if (isScored) Color.Black else Color.DarkGray.copy(alpha = 0.4f), animationSpec = tween(durationMillis = 500))

    Button(
        modifier = Modifier
            .padding(padding)
            .width(80.dp)
            .height(80.dp)
            .border(2.dp, Color.Black),
        onClick = onClick,
        colors = ButtonDefaults.buttonColors(
            backgroundColor = backgroundColor,
            contentColor = contentColor,
        )
    ) {
        Text("${index + 1}", fontSize = 36.sp)
    }
}

@Composable
fun TeamComponent(gameState: GameState, team: Team, onGameStateUpdated: (Int) -> Unit) {
    require(team != Team.None)

    Column(
        horizontalAlignment = Alignment.CenterHorizontally,
        modifier = Modifier.padding(24.dp)
    ) {
        team.image?.let { Image(painterResource(it), null) }
        Text(
            gameState.score(team).toString(),
            fontSize = 200.sp,
            fontWeight = FontWeight.Bold,
            textAlign = TextAlign.Center,
            color = Color.White,
            modifier = Modifier
                .padding(vertical = 24.dp)
                .background(Color.Black)
                .width(466.dp)
        )

        Row(horizontalArrangement = Arrangement.Center) {
            for (i in 0 until GAMES_COUNT / 2) {
                ScoreButton(gameState, i, team) { onGameStateUpdated(i) }
            }
        }
        Row(horizontalArrangement = Arrangement.Center) {
            for (i in GAMES_COUNT / 2 until GAMES_COUNT) {
                ScoreButton(gameState, i, team) { onGameStateUpdated(i) }
            }
        }
    }
}