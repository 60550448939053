@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package schlagdenvorstand.composeapp.generated.resources

import kotlin.OptIn
import kotlin.String
import kotlin.collections.MutableMap
import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.InternalResourceApi

private object CommonMainDrawable0 {
  public val lorbeer: DrawableResource by 
      lazy { init_lorbeer() }

  public val mitarbeiter: DrawableResource by 
      lazy { init_mitarbeiter() }

  public val vorstand: DrawableResource by 
      lazy { init_vorstand() }
}

@InternalResourceApi
internal fun _collectCommonMainDrawable0Resources(map: MutableMap<String, DrawableResource>) {
  map.put("lorbeer", CommonMainDrawable0.lorbeer)
  map.put("mitarbeiter", CommonMainDrawable0.mitarbeiter)
  map.put("vorstand", CommonMainDrawable0.vorstand)
}

internal val Res.drawable.lorbeer: DrawableResource
  get() = CommonMainDrawable0.lorbeer

private fun init_lorbeer(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:lorbeer",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/schlagdenvorstand.composeapp.generated.resources/drawable/lorbeer.png", -1, -1),
    )
)

internal val Res.drawable.mitarbeiter: DrawableResource
  get() = CommonMainDrawable0.mitarbeiter

private fun init_mitarbeiter(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:mitarbeiter",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/schlagdenvorstand.composeapp.generated.resources/drawable/mitarbeiter.svg", -1, -1),
    )
)

internal val Res.drawable.vorstand: DrawableResource
  get() = CommonMainDrawable0.vorstand

private fun init_vorstand(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:vorstand",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/schlagdenvorstand.composeapp.generated.resources/drawable/vorstand.svg", -1, -1),
    )
)
